

















































































































































import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import IconCheck from '@/components/specific/IconCheck.vue';
import IconCloseMini from '@/components/specific/IconCloseMini.vue';
import IconHint from '@/components/specific/IconHint.vue';
import IconSearch from '@/components/specific/IconSearch.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIGroupMapper } from '@/store/modules/ui/group';
import type { User } from '@/store/modules/ui/common';
import type { Group } from '@/store/modules/ui/group';

const GroupNameMaxLength = 8;
const GroupMemberMaxLength = 18;
const GroupService = ServiceFactory.get('group');
const GroupMemberService = ServiceFactory.get('groupMember');

export default Vue.extend({
  name: 'GroupEditorPopup',

  components: {
    IconCheck,
    IconCloseMini,
    IconHint,
    IconSearch,
  },

  data(): {
    filteredUserList: User[];
    groupMemberMaxLength: number;
    isProcessing: boolean;
    searchText: string;
    selectedMemberIds: string[];
    selectedMemberListPositionX: number;
    updateParams: {
      groupName: string;
      groupMemberIds: string[];
    };
  } {
    return {
      filteredUserList: [],
      groupMemberMaxLength: GroupMemberMaxLength,
      isProcessing: false,
      searchText: '',
      selectedMemberIds: [],
      selectedMemberListPositionX: 0,
      updateParams: {
        groupMemberIds: [],
        groupName: '',
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['userList', 'editGroupId', 'showedGroupEditorPopup']),
    ...UIGroupMapper.mapState(['groupList']),

    existSelectedMember(): boolean {
      return this.selectedMemberIds.length > 0;
    },

    headerLabel(): string {
      return this.isCreate ? 'グループ新規作成' : 'グループ編集';
    },

    isCreate(): boolean {
      return this._.isEmpty(this.editGroupId);
    },

    isUpdate(): boolean {
      return !this._.isEmpty(this.editGroupId);
    },

    suffixRemainColor(): string {
      return this.textRemainCounter < 0 ? 'suffix-red' : '';
    },

    textRemain(): string {
      const text = this.textRemainCounter >= 0 ? this.textRemainCounter : 0;
      return text.toString();
    },

    textRemainCounter(): number {
      return GroupNameMaxLength - this.updateParams.groupName.length;
    },
  },

  watch: {
    showedGroupEditorPopup: {
      handler() {
        if (this.showedGroupEditorPopup) {
          this.selectedMemberIds = [];
          this.initializeForm();
          this.filter();
        }
      },
    },
  },

  created() {
    this.filter();
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage']),
    ...UIGroupMapper.mapActions(['setGroupList']),

    backToGroupListPopup() {
      this.$emit('click-back');
    },

    closePopup() {
      this.$emit('click-close');
    },

    async createGroup() {
      const self = this;
      const { workspaceId, userId } = this.userAttributes;

      if (self.isProcessing) {
        return;
      }

      self.updateParams.groupMemberIds = self._.cloneDeep(self.selectedMemberIds);
      self.$v.updateParams.groupMemberIds.$model = self.updateParams.groupMemberIds;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.isProcessing = true;

      try {
        const createGroupParams = {
          // API仕様書記載順
          /* eslint-disable vue/sort-keys */
          workspaceId,
          userId,
          groupName: self.updateParams.groupName,
          /* eslint-enable vue/sort-keys */
        };
        const responseGroup = await GroupService.createGroup(createGroupParams);

        const replaceGroupMemberParams = {
          // API仕様書記載順
          /* eslint-disable vue/sort-keys */
          workspaceId,
          userId,
          groupId: responseGroup.groupId,
          memberIds: self.updateParams.groupMemberIds,
          /* eslint-enable vue/sort-keys */
        };
        const procedures = [
          GroupMemberService.replaceGroupMember(replaceGroupMemberParams),
          GroupService.getGroupList(workspaceId, userId),
        ];

        const [, responseGroup2] = await Promise.all(procedures);

        // グループ一覧情報をストアにセット
        const groupList: Group[] = responseGroup2 as Group[];
        const sorted = self._.sortBy(groupList, ['groupName']);
        self.setGroupList({ groups: sorted });

        self.setMessage({ color: 'success', text: 'グループを作成しました。' });

        self.$emit('create-group', true);
      } catch (error) {
        self.$emit('create-group', false);
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }

      self.isProcessing = false;
    },

    filter() {
      const { userId } = this.userAttributes;

      if (this._.isEmpty(this.searchText)) {
        this.filteredUserList = this._.cloneDeep(this.userList);
      } else {
        const searchTextKana = this.searchText.replace(/[\u3041-\u3096]/g, (ch) =>
          String.fromCharCode(ch.charCodeAt(0) + 0x60)
        );
        this.filteredUserList = this.userList.filter((v: User) => {
          return (
            v.kana?.includes(searchTextKana) ||
            v.kana?.includes(this.searchText) ||
            v.userName?.includes(this.searchText)
          );
        });
      }
      this._.remove(this.filteredUserList, { userId });
    },

    getUser(userId: string): User {
      const user = this._.find(this.userList, { userId });
      return user as User;
    },

    async initializeForm() {
      const self = this;
      const { workspaceId, userId } = this.userAttributes;

      if (self._.isEmpty(this.editGroupId)) {
        self.updateParams.groupName = '';
        self.selectedMemberIds = [];
        self.searchText = '';
        self.$v.$reset();
        return;
      }

      const group = this._.find(self.groupList, { groupId: self.editGroupId });

      if (self._.isEmpty(group) || this._.isUndefined(group)) {
        self.setMessage({ color: 'error', text: 'グループが存在しません。' });
        return;
      }

      const responseGroupMember = await GroupMemberService.getGroupMemberList(
        workspaceId,
        userId,
        self.editGroupId
      );

      self.selectedMemberIds = self._.map(responseGroupMember, 'memberId');
      self.updateParams.groupName = group.groupName;
      self.searchText = '';
      self.$v.$reset();
      self.filter();
    },

    memberSelected(userId: string): boolean {
      return this._.includes(this.selectedMemberIds, userId);
    },

    toggleMemberSelected(user: User) {
      const self = this;
      if (!self.memberSelected(user.userId)) {
        self.selectedMemberIds.push(user.userId);
      } else {
        self.unselectMember(user.userId);
        return;
      }
      self.updateParams.groupMemberIds = self._.cloneDeep(self.selectedMemberIds);
      self.$v.updateParams.groupMemberIds.$model = self.updateParams.groupMemberIds;
      self.$v.updateParams.groupMemberIds.$touch();
    },

    unselectMember(userId: string) {
      const self = this;
      if (self.memberSelected(userId)) {
        const index = self.selectedMemberIds.indexOf(userId);
        self.selectedMemberIds.splice(index, 1);
      }
      self.updateParams.groupMemberIds = self._.cloneDeep(self.selectedMemberIds);
      self.$v.updateParams.groupMemberIds.$model = self.updateParams.groupMemberIds;
      self.$v.updateParams.groupMemberIds.$touch();
    },

    async updateGroup() {
      const self = this;
      const { workspaceId, userId } = this.userAttributes;

      if (self.isProcessing) {
        return;
      }

      self.updateParams.groupMemberIds = self._.cloneDeep(self.selectedMemberIds);
      self.$v.updateParams.groupMemberIds.$model = self.updateParams.groupMemberIds;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.isProcessing = true;

      try {
        const updateGroupParams = {
          groupName: self.updateParams.groupName,
        };
        await GroupService.updateGroup(workspaceId, userId, self.editGroupId, updateGroupParams);

        const replaceGroupMemberParams = {
          // API仕様書記載順
          /* eslint-disable vue/sort-keys */
          workspaceId,
          userId,
          groupId: self.editGroupId,
          memberIds: self.updateParams.groupMemberIds,
          /* eslint-enable vue/sort-keys */
        };
        const procedures = [
          GroupMemberService.replaceGroupMember(replaceGroupMemberParams),
          GroupService.getGroupList(workspaceId, userId),
        ];

        const [, responseGroup] = await Promise.all(procedures);

        // グループ一覧情報をストアにセット
        const groupList: Group[] = responseGroup as Group[];
        const sorted = self._.sortBy(groupList, ['groupName']);
        self.setGroupList({ groups: sorted });

        self.setMessage({ color: 'success', text: 'グループを更新しました。' });

        self.$emit('update-group', true);
      } catch (error) {
        self.$emit('update-group', false);
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }

      self.isProcessing = false;
    },

    userNameShorten(user: User): string {
      // TODO 実際には必ず名前は入っているので、このコードはリリース前に修正する
      return this._.get(user, 'userName', '不明').substring(0, 2);
    },

    wheelSelectedMember(e: WheelEvent) {
      const selectedMemberList = this.$refs.selectedMemberList as HTMLElement;
      this.selectedMemberListPositionX += e.deltaY / 5;

      if (this.selectedMemberListPositionX > -5) {
        this.selectedMemberListPositionX = -5;
      }

      selectedMemberList.style.left = `${this.selectedMemberListPositionX}px`;
    },
  },

  validations() {
    return {
      updateParams: {
        // 表示順序で記述
        /* eslint-disable vue/sort-keys */
        groupName: {
          required,
          maxLength: maxLength(GroupNameMaxLength),
        },
        groupMemberIds: {
          required,
          maxLength: maxLength(GroupMemberMaxLength),
        },
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
