








export default {
  name: 'IconUsers',
};
