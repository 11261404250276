



























import Vue from 'vue';

export default Vue.extend({
  name: 'GroupInitialScreen',

  methods: {
    createGroup() {
      this.$emit('click-create');
    },
  },
});
