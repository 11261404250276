import { render, staticRenderFns } from "./Group.vue?vue&type=template&id=dfd86126&scoped=true&"
import script from "./Group.vue?vue&type=script&lang=ts&"
export * from "./Group.vue?vue&type=script&lang=ts&"
import style0 from "./Group.vue?vue&type=style&index=0&id=dfd86126&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfd86126",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCardActions,VCardText,VSelect})
