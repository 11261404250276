


























































import Vue from 'vue';
import UserStatus from '@/components/specific/UserStatus.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIGroupMapper } from '@/store/modules/ui/group';
import { InitialSearchConditions } from '@/store/modules/ui/memberFilter';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import type { Group } from '@/store/modules/ui/group';
import type { PartialMember, Relationship } from '@/store/modules/ui/relationship';

const SearchService = ServiceFactory.get('search');
const GroupMemberService = ServiceFactory.get('groupMember');
const RelationshipService = ServiceFactory.get('relationship');

export default Vue.extend({
  name: 'GroupCircle',

  components: {
    UserStatus,
  },

  props: {
    group: {
      type: Object as Vue.PropType<Group> | undefined,
      required: true,
    },
  },

  data(): {
    groupMembers: PartialMember[];
  } {
    return {
      groupMembers: [],
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIGroupMapper.mapState(['groupList']),
    ...UIRelationshipMapper.mapState(['relationship']),

    color(): string {
      return this._.sample(['blue', 'green']) || 'blue';
    },

    isLarge(): boolean {
      return this.groupMembers.length >= 12;
    },
  },

  watch: {
    group: {
      handler() {
        this.refreshGroupMembers();
      },
    },

    groupList: {
      handler() {
        this.refreshGroupMembers();
      },
      deep: true,
    },

    relationship: {
      handler() {
        this.refreshGroupMembers();
      },
    },
  },

  created() {
    this.refreshGroupMembers();
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage']),

    async refreshGroupMembers() {
      const self = this;
      const { workspaceId, userId } = this.userAttributes;

      if (self._.isEmpty(self.group.groupId)) {
        return;
      }

      try {
        const procedures = [
          RelationshipService.getRelationshipSorted(workspaceId, userId),
          SearchService.memberSearch(InitialSearchConditions),
          GroupMemberService.getGroupMemberList(workspaceId, userId, self.group.groupId),
        ];
        const [resRelationship, resSearch, resGroupMember] = await Promise.all(procedures);

        // 自分を中心（配列0番）にする
        const relationship: Relationship = [];
        const me = self._.find(resSearch, { userId });
        relationship.push(me);

        if (!self._.isEmpty(resSearch)) {
          // サーチの結果をリレーションシップ順に並べる
          const sortedMembers = resRelationship
            // APIレスポンスなのでanyを許容
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((x: any) => resSearch.find((v: any) => v.userId === x.userId))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((e: any) => e);

          // 自分以外を関係が近い順に並べる
          self._.forEach(sortedMembers, (member) => {
            if (member.userId !== userId) {
              relationship.push(member);
            }
          });
        }

        self.groupMembers = [];

        self._.forEach(relationship, (member: PartialMember) => {
          const groupMember = self._.find(resGroupMember, { memberId: member.userId });
          if (!self._.isUndefined(groupMember)) {
            self.groupMembers.push(member);
          }
        });
      } catch (error) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }
    },
  },
});
