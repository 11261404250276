



















import Vue from 'vue';

export default Vue.extend({
  name: 'GroupListContextMenu',

  methods: {
    deleteGroup() {
      this.$emit('click-delete');
    },

    editGroup() {
      this.$emit('click-edit');
    },
  },
});
