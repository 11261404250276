








export default {
  name: 'IconCheck',
};
