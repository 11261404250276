









































































































import Vue from 'vue';
import GroupCircle from '@/components/specific/GroupCircle.vue';
import GroupEditorPopup from '@/components/specific/GroupEditorPopup.vue';
import GroupInitialScreen from '@/components/specific/GroupInitialScreen.vue';
import GroupListPopup from '@/components/specific/GroupListPopup.vue';
import GroupSettingPopup from '@/components/specific/GroupSettingPopup.vue';
import IconCog from '@/components/specific/IconCog.vue';
import IconUsers from '@/components/specific/IconUsers.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIGroupMapper } from '@/store/modules/ui/group';
import type { Group } from '@/store/modules/ui/group';

const GroupService = ServiceFactory.get('group');

export default Vue.extend({
  name: 'Group',

  components: {
    GroupCircle,
    GroupEditorPopup,
    GroupInitialScreen,
    GroupListPopup,
    GroupSettingPopup,
    IconCog,
    IconUsers,
  },

  data(): {
    debug: {
      innerWidth: number;
      innerHeight: number;
      clientWidth: number;
      clientHeight: number;
      scrollWidth: number;
      scrollHeight: number;
      scrollLeft: number;
      scrollTop: number;
      scrollX: number;
      scrollY: number;
    };
    dialogParams: {
      delete: {
        groupId: string;
        groupName: string;
      };
    };
    groupMap: {
      [key: number]: Group;
    };
    isProcessing: boolean;
    selectedRatio: number;
    showedDialog: {
      [key: string]: boolean;
    };
    zoomRatio: {
      text: string;
      value: number;
    }[];
  } {
    return {
      debug: {
        /* eslint-disable vue/sort-keys */
        innerWidth: 0,
        innerHeight: 0,
        clientWidth: 0,
        clientHeight: 0,
        scrollWidth: 0,
        scrollHeight: 0,
        scrollLeft: 0,
        scrollTop: 0,
        scrollX: 0,
        scrollY: 0,
        /* eslint-enable vue/sort-keys */
      },
      dialogParams: {
        delete: {
          groupId: '',
          groupName: '',
        },
      },
      groupMap: {},
      isProcessing: false,
      selectedRatio: 1,
      showedDialog: {
        delete: false,
      },
      zoomRatio: [
        {
          text: '100%',
          value: 1,
        },
        {
          text: '75%',
          value: 0.75,
        },
        {
          text: '50%',
          value: 0.5,
        },
        {
          text: '25%',
          value: 0.25,
        },
      ],
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState([
      'showedGroupEditorPopup',
      'showedGroupInitialScreen',
      'showedGroupListPopup',
      'showedGroupSettingPopup',
      'zoom',
    ]),
    ...UIGroupMapper.mapState(['groupList']),
  },

  watch: {
    groupList: {
      handler() {
        this.makeGroupMap();
      },
    },
  },

  created() {
    this.hideAllPopup();
    this.clearEditGroupId();
    this.initializeGroupInitialScreen();
    this.loadGroups();
    this.makeGroupMap();
    this.selectedRatio = this.zoom.group;
  },

  mounted() {
    this.initializeScrollbars();
    this.zoomChanged(this.selectedRatio);

    window.addEventListener('resize', this.handleResize);
  },

  methods: {
    ...UICommonMapper.mapActions([
      'clearEditGroupId',
      'hideAllPopup',
      'hideGroupEditorPopup',
      'hideGroupInitialScreen',
      'hideGroupListPopup',
      'hideGroupSettingPopup',
      'hidePopupBackgroundLayer',
      'setEditGroupId',
      'setNavigating',
      'setMessage',
      'setZoom',
      'showGroupEditorPopup',
      'showGroupInitialScreen',
      'showGroupListPopup',
      'showGroupSettingPopup',
      'showPopupBackgroundLayer',
    ]),
    ...UIGroupMapper.mapActions(['setGroupList']),

    closeDeleteDialog() {
      this.dialogParams.delete.groupId = '';
      this.dialogParams.delete.groupName = '';
      this.showedDialog.delete = false;
    },

    closeGroupEditorPopup() {
      this.hideGroupEditorPopup();
      this.hidePopupBackgroundLayer();
    },

    closeGroupListPopup() {
      this.hideGroupListPopup();
      this.hidePopupBackgroundLayer();
    },

    closeGroupSettingPopup() {
      this.hideAllPopup();
    },

    async deleteGroup() {
      const self = this;
      const { workspaceId, userId } = this.userAttributes;

      if (self.isProcessing) {
        return;
      }

      self.isProcessing = true;

      try {
        await GroupService.deleteGroup(workspaceId, userId, this.dialogParams.delete.groupId);

        const responseGroup = await GroupService.getGroupList(workspaceId, userId);

        // グループ一覧情報をストアにセット
        const groupList: Group[] = responseGroup as Group[];
        const sorted = self._.sortBy(groupList, ['groupName']);
        self.setGroupList({ groups: sorted });

        self.setMessage({ color: 'success', text: 'グループを削除しました。' });
        self.closeDeleteDialog();
      } catch (error: any) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }

      self.isProcessing = false;
    },

    groupCreated(success: boolean) {
      if (success) {
        this.hideGroupInitialScreen();
        this.hideGroupEditorPopup();
        this.showGroupListPopup();
      }
    },

    groupUpdated(success: boolean) {
      if (success) {
        this.hideGroupEditorPopup();
        this.showGroupListPopup();
      }
    },

    handleResize() {
      this.debug.innerWidth = window.innerWidth;
      this.debug.innerHeight = window.innerHeight;
    },

    handleScroll() {
      const $el = this._.get(this.$refs.scroll, '$el');
      this.debug.clientWidth = $el.clientWidth;
      this.debug.clientHeight = $el.clientHeight;
      this.debug.scrollWidth = $el.scrollWidth;
      this.debug.scrollHeight = $el.scrollHeight;
      this.debug.scrollLeft = Math.trunc($el.scrollLeft);
      this.debug.scrollTop = Math.trunc($el.scrollTop);
      this.debug.scrollX = window.scrollX;
      this.debug.scrollY = window.scrollY;
    },

    initializeGroupInitialScreen() {
      if (this.groupList.length === 0) {
        this.showGroupInitialScreen();
      }
    },

    initializeScrollbars() {
      const $el = this._.get(this.$refs.scroll, '$el');
      this.debug.innerWidth = window.innerWidth;
      this.debug.innerHeight = window.innerHeight;
      this.debug.clientWidth = $el.clientWidth;
      this.debug.clientHeight = $el.clientHeight;
      this.debug.scrollWidth = $el.scrollWidth;
      this.debug.scrollHeight = $el.scrollHeight;
      this.debug.scrollLeft = Math.trunc($el.scrollLeft);
      this.debug.scrollTop = Math.trunc($el.scrollTop);
      this.debug.scrollX = window.scrollX;
      this.debug.scrollY = window.scrollY;
      $el.scrollLeft = ($el.scrollWidth - window.innerWidth + 34) / 2;
      $el.scrollTop = ($el.scrollHeight - window.innerHeight + 68) / 2;
    },

    async loadGroups() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      try {
        const responseGroup = await GroupService.getGroupList(workspaceId, userId);

        const groupList: Group[] = responseGroup as Group[];
        const sorted = self._.sortBy(groupList, ['groupName']);
        self.setGroupList({ groups: sorted });
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },

    makeGroupMap() {
      const self = this;
      [...Array(10)].forEach((_, i) => {
        const position = i + 1;
        const group = self._.find(self.groupList, { position }) as Group | undefined;
        if (self._.isUndefined(group)) {
          const emptyGroup: Group = {
            // 定義順
            /* eslint-disable vue/sort-keys */
            workspaceId: '',
            userId: '',
            groupId: '',
            groupName: '',
            position,
            /* eslint-enable vue/sort-keys */
          };

          self.groupMap[position] = emptyGroup;
        } else {
          self.groupMap[position] = group;
        }
      });
    },

    openGroupListPopup() {
      this.clearEditGroupId();
      this.hideAllPopup();
      this.showPopupBackgroundLayer();
      this.showGroupListPopup();
    },

    openGroupSettingPopup() {
      this.hideAllPopup();
      this.showPopupBackgroundLayer();
      this.showGroupSettingPopup();
    },

    showCreateGroupPopup() {
      this.clearEditGroupId();
      this.hideGroupListPopup();
      this.showGroupEditorPopup();
    },

    showDeleteGroupDialog(groupId: string) {
      this.hideAllPopup();
      this.dialogParams.delete.groupId = groupId;
      const group = this._.find(this.groupList, { groupId }) as Group;
      this.dialogParams.delete.groupName = group.groupName;
      this.showedDialog.delete = true;
    },

    showEditGroupPopup(groupId: string) {
      this.setEditGroupId({ editGroupId: groupId });
      this.showPopupBackgroundLayer();
      this.hideGroupListPopup();
      this.showGroupEditorPopup();
    },

    // サークルの縮尺を変更する
    zoomChanged(ratio: number) {
      const mapContainer = this.$refs.mapContainer as InstanceType<typeof HTMLSelectElement>;

      if (ratio === 1) {
        mapContainer.style.transform = '';
      } else {
        mapContainer.style.transform = `scale(${ratio}, ${ratio})`;
      }
      this.setZoom({ group: ratio });
    },
  },
});
