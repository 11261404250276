


























































import Vue from 'vue';
import GroupListContextMenu from '@/components/specific/GroupListContextMenu.vue';
import { UIGroupMapper } from '@/store/modules/ui/group';

export default Vue.extend({
  name: 'GroupListPopup',

  components: {
    GroupListContextMenu,
  },

  props: {
    showed: {
      type: Boolean,
      required: true,
    },
  },

  data(): {
    selectedGroupId: string;
    showedContextMenu: boolean;
  } {
    return {
      selectedGroupId: '',
      showedContextMenu: false,
    };
  },

  computed: {
    ...UIGroupMapper.mapState(['groupList']),
  },

  watch: {
    groupList: {
      handler() {
        this.showedContextMenu = false;
      },
    },

    showed: {
      handler() {
        if (this.showed) {
          this.showedContextMenu = false;
        }
      },
    },
  },

  methods: {
    closePopup() {
      this.showedContextMenu = false;
      this.$emit('click-close');
    },

    // itemHovered(groupId: string): boolean {
    //   const item = this._.find(this.showedContextMenu, { groupId });
    //   if (item) {
    //     return item.showed;
    //   }
    //   return false;
    // },

    deleteGroup() {
      this.$emit('click-delete', this.selectedGroupId);
    },

    editGroup() {
      this.$emit('click-edit', this.selectedGroupId);
    },

    // 選択グループのactive-classを有効にする
    itemSelected(groupId: string): boolean {
      return groupId === this.selectedGroupId;
    },

    showCreateGroupPopup() {
      this.$emit('click-create');
    },

    toggleContextMenu(groupId: string, event: PointerEvent) {
      const contextMenu = this.$refs.contextMenu as HTMLDivElement; // InstanceType<typeof HTMLElement>;

      if (this.showedContextMenu && this.selectedGroupId === groupId) {
        this.selectedGroupId = '';
        this.showedContextMenu = false;
      } else {
        this.selectedGroupId = groupId;
        this.showedContextMenu = true;
        contextMenu.style.top = `${event.clientY - 110}px`;
      }
    },
  },
});
