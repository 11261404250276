








export default {
  name: 'IconCog',
};
