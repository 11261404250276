












































































































































































import Vue from 'vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIGroupMapper } from '@/store/modules/ui/group';
import type { Group } from '@/store/modules/ui/group';

type dragItem = {
  groupId: string;
  groupName: string;
  position: number;
};

const GroupService = ServiceFactory.get('group');

export default Vue.extend({
  name: 'GroupSettingPopup',

  data(): {
    drag: boolean;
    // 表示するグループ
    leftItems: {
      [key: number]: dragItem;
    };
    // 表示しないグループ
    rightItems: dragItem[];
  } {
    return {
      drag: false,
      leftItems: {},
      rightItems: [],
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIGroupMapper.mapState(['groupList']),
  },

  watch: {
    groupList: {
      handler() {
        this.prepareDragItems();
      },
    },
  },

  created() {
    this.prepareDragItems();
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage']),

    closePopup() {
      this.$emit('click-close');
    },

    filled(position: number): boolean {
      const item = this.getItem(position);
      return !this._.isUndefined(item);
    },

    getGroupName(position: number): string {
      const item = this.getItem(position);
      return this._.isUndefined(item) ? '' : item.groupName;
    },

    getItem(position: number): dragItem | undefined {
      const leftItems = this._.flatten(this._.values(this.leftItems));
      const item = this._.find(leftItems, { position });
      if (!this._.isUndefined(item)) {
        return item as dragItem;
      }
      return undefined;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onDropLeft(position: number, transferData: any) {
      const self = this;
      const groupId = self._.get(transferData, 'group.groupId');
      const positionOrigin = self._.get(transferData, 'group.position', 0);

      const moveItem: dragItem = {
        groupId,
        groupName: self._.get(transferData, 'group.groupName'),
        position,
      };

      // 移動先位置にいる先住グループ
      const originallyItem: dragItem = self.leftItems[position];

      if (!self._.isUndefined(originallyItem)) {
        // 自位置への再ドロップなら何もしない
        if (originallyItem.groupId === moveItem.groupId) {
          return;
        }

        // 先住がいれば、先住を 表示しない に移動
        if (originallyItem.position === position) {
          originallyItem.position = 0;
          self.rightItems.push(originallyItem);
        }
      }

      self.$set(self.leftItems, position, moveItem);

      if (positionOrigin === 0) {
        // 表示しない → 表示する
        self.rightItems = self._.reject(self.rightItems, { groupId });
      } else if (position !== positionOrigin) {
        // 表示する → 表示する（位置変更）
        self.$delete(self.leftItems, positionOrigin);
      }

      // 位置登録
      try {
        const { workspaceId, userId } = self.userAttributes;
        await GroupService.updateGroup(workspaceId, userId, groupId, { position });

        self.setMessage({ color: 'success', text: 'グループを移動しました。' });
        self.$emit('position-updated');
      } catch (error) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onDropRight(transferData: any) {
      const self = this;
      const groupId = self._.get(transferData, 'group.groupId');
      const positionOrigin = self._.get(transferData, 'group.position', 0);

      const existGroup = self._.find(self.rightItems, { groupId });

      // 表示しない への再ドロップなら何もしない
      if (!self._.isUndefined(existGroup)) {
        return;
      }

      // 表示する から 表示しない へ
      self.$delete(self.leftItems, positionOrigin);
      const moveItem: dragItem = {
        groupId,
        groupName: self._.get(transferData, 'group.groupName'),
        position: 0,
      };
      self.rightItems.push(moveItem);

      // 位置登録
      try {
        const { workspaceId, userId } = self.userAttributes;
        await GroupService.updateGroup(workspaceId, userId, groupId, { position: 0 });

        self.setMessage({ color: 'success', text: 'グループを移動しました。' });
        self.$emit('position-updated');
      } catch (error) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },

    prepareDragItems() {
      const self = this;
      self.rightItems = [];
      self.leftItems = {};

      self._.forEach(self.groupList, (group: Group) => {
        const { groupId, groupName } = group;
        const position = self._.get(group, 'position', 0);
        if (position === 0) {
          self.rightItems.push({ groupId, groupName, position });
        } else {
          self.$set(self.leftItems, position, { groupId, groupName, position });
        }
      });
    },
  },
});
