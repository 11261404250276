var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-center-horizontal group-setting-popup"},[_c('div',{staticClass:"screen"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"header-title notosansjp-medium-mirage-16px"},[_vm._v("グループ表示設定")]),_c('BaseButton',{staticClass:"icon-close",attrs:{"icon":"","outlined":false},on:{"click":function($event){$event.stopPropagation();return _vm.closePopup($event)},"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}}},[_c('VImg',{attrs:{"src":require('@/assets/icons/icon-close.svg')}})],1)],1)]),_c('div',{staticClass:"description notosansjp-normal-mirage-14px"},[_vm._v(" ドラッグ&ドロップして、グループの位置や表示を変更することができます。 ")]),_c('div',{staticClass:"drag-container"},[_c('div',{staticClass:"left-box"},[_c('div',{staticClass:"background"}),_c('div',{staticClass:"label notosansjp-medium-quick-silver-14px"},[_vm._v("表示する")]),_c('div',{staticClass:"palette"},[_c('div',{staticClass:"position-circle-container position-circle-container-1"},[_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 1 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-1', _vm.filled(1) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(1)),expression:"filled(1)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(1) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(1))+" ")])])],1)]),_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 2 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-2', _vm.filled(2) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(2)),expression:"filled(2)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(2) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(2))+" ")])])],1)]),_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 3 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-3', _vm.filled(3) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(3)),expression:"filled(3)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(3) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(3))+" ")])])],1)])],1),_c('div',{staticClass:"position-circle-container position-circle-container-2"},[_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 4 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-3', _vm.filled(4) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(4)),expression:"filled(4)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(4) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(4))+" ")])])],1)]),_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 5 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-3', _vm.filled(5) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(5)),expression:"filled(5)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(5) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(5))+" ")])])],1)]),_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 6 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-3', _vm.filled(6) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(6)),expression:"filled(6)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(6) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(6))+" ")])])],1)]),_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 7 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-3', _vm.filled(7) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(7)),expression:"filled(7)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(7) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(7))+" ")])])],1)])],1),_c('div',{staticClass:"position-circle-container position-circle-container-3"},[_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 8 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-3', _vm.filled(8) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(8)),expression:"filled(8)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(8) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(8))+" ")])])],1)]),_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 9 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-3', _vm.filled(9) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(9)),expression:"filled(9)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(9) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(9))+" ")])])],1)]),_c('drop',{staticClass:"drop",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onDropLeft.apply(void 0, [ 10 ].concat( argsArray ))}}},[_c('div',{class:['position-circle', 'position-3', _vm.filled(10) ? 'filled' : '']},[_c('drag',{directives:[{name:"show",rawName:"v-show",value:(_vm.filled(10)),expression:"filled(10)"}],staticClass:"drag",attrs:{"transfer-data":{ group: _vm.getItem(10) }}},[_c('div',{staticClass:"group-name notosansjp-medium-baby-powder-12px"},[_vm._v(" "+_vm._s(_vm.getGroupName(10))+" ")])])],1)])],1)])]),_c('PerfectScrollbar',[_c('drop',{staticClass:"drop",on:{"drop":function($event){return _vm.onDropRight.apply(void 0, arguments)}}},[_c('div',{staticClass:"right-box"},[_c('div',{staticClass:"background"}),_c('div',{staticClass:"label notosansjp-medium-quick-silver-14px"},[_vm._v("表示しない")]),_c('div',{staticClass:"item-list"},_vm._l((_vm.rightItems),function(item){return _c('drag',{key:item.groupId,staticClass:"drag item",attrs:{"transfer-data":{ group: item }}},[_c('div',{staticClass:"text notosansjp-normal-boulder-14px"},[_vm._v(" "+_vm._s(item.groupName)+" ")])])}),1)])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }