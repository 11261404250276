import { render, staticRenderFns } from "./IconUsers.vue?vue&type=template&id=cc1f81fa&scoped=true&"
import script from "./IconUsers.vue?vue&type=script&lang=ts&"
export * from "./IconUsers.vue?vue&type=script&lang=ts&"
import style0 from "./IconUsers.vue?vue&type=style&index=0&id=cc1f81fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc1f81fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
