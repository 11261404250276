








export default {
  name: 'IconHint',
};
